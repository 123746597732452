import FooterCopyrites from "./FooterCopyrites";
import FooterFeatures from "./FooterFeatures";
import "./style.css";
const Footer = () => {
    return (
        <div id="footer" className="container-footer">
            <FooterFeatures />
            <FooterCopyrites />
        </div>
    );
};
export default Footer;
