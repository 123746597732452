import dRaras from '../../_assets/d-raras.png';
import educacao from '../../_assets/educacao.png';
import esportes from '../../_assets/esportes.png';
import lazer from '../../_assets/lazer.png';
import saude from '../../_assets/saude.png';
import vDiaria from '../../_assets/v-diaria.png';

const cards = [
    {
        cardId: 1,
        cardImage: esportes,
        cardLink: 'https://www.google.com.br/',
        title: ''
    },
    {
        cardId: 2,
        cardImage: educacao,
        cardLink: 'https://www.google.com.br/',
         title: ''
    },
    {
        cardId: 3,
        cardImage: lazer,
        cardLink: 'https://www.google.com.br/',
         title: ''
    },
    {
        cardId: 4,
        cardImage: saude,
        cardLink: 'https://www.google.com.br/',
         title: ''
    },
    {
        cardId: 5,
        cardImage: vDiaria,
        cardLink: 'https://www.google.com.br/',
        title: ''
    },
    {
        cardId: 6,
        cardImage: dRaras,
        cardLink: 'https://www.google.com.br/',
        title: ''
    },
];
export { cards };
